<template>
  <b-row>
    <b-col md="3" class="mt-4">
      <b-card>
        <ul class="list-unstyled">
          <li
            v-bind:class="{ 'btn-flat-primary': selectedReport === x.title }"
            @click="
              () => {
                selectedReport = x.title;
                selected = x;
              }
            "
            class="p-1 nav-item-report cursor-pointer border-bottom"
            v-for="x in reports"
            :key="x.title"
            v-permission="x.permission"
          >
            <feather-icon :icon="x.icon" size="20" class="mr-75" />
            <span>{{ $t(x.title) }}</span>
          </li>
        </ul>
      </b-card>
    </b-col>
    <b-col md="9">
      <b-card-title
        v-if="selectedReport"
        class="p-0 m-0 text-primary text-darken-5"
      >
        <b-row class="p-0">
          <b-col md="12" class="text-center">
            <span>{{ $t(selectedReport) }}</span>
          </b-col>
        </b-row>
      </b-card-title>
      <hr v-if="selectedReport" class="border-info border-darken-2" />
      <employeeList v-if="selectedReport === 'employees'"/>
      <employeePayroll :type="selected" v-if="selectedReport === 'employeePayroll'"/>
      <EmployeeBenefits :type="selected" v-if="selectedReport === 'benefits'"/>
      <EmployeeBenefits :type="selected" v-if="selectedReport === 'deductions'"/>
      <EmployeeBenefits :type="selected" v-if="selectedReport === 'advancedPayments'"/>
      <employeesData :type="selected" v-if="selectedReport === 'employeesData'"/>
      <employeesResidence :type="selected" v-if="selectedReport === 'employeesResidence'"/>
      <salariesTotal :type="selected" v-if="selectedReport === 'salariesTotal'"/>
      <employeesVacations :type="selected" v-if="selectedReport === 'employeesVacations'"/>
    </b-col>
  </b-row>
</template>

<script>
import { employeesReports } from '@/libs/acl/Lookups';
import employeeList from '@/pages/Settings/Report/employeeList.vue';
import employeesData from '@/pages/Settings/Report/employeesData.vue';
import EmployeeBenefits from '@/pages/Settings/Report/EmployeeBenefits.vue';
import employeePayroll from '@/pages/Settings/Report/employeePayroll.vue'
import reportMixin from '@/mixin/reportMixin';
import employeesResidence from '@/pages/Settings/Report/employeesResidence.vue';
import salariesTotal from '@/pages/Settings/Report/salariesTotal.vue';
import employeesVacations from '@/pages/Settings/Report/employeesVacations.vue';

export default {
  components: {
    employeeList,
    employeesData,
    EmployeeBenefits,
    employeePayroll,
    employeesResidence,
    salariesTotal,
    employeesVacations
  },
  mixins: [
  reportMixin,
  ],
  data() {
    return {
      menu: employeesReports,
      reports: [],
      selectedReport: '',
      selected: { permission: '' }
    };
  },
  computed: {},
  mounted() {
    this.reports = this.menu;
    this.selectedReport = 'employees'
     if (this.profile.permissions.length > 0) {
    this.selectedReport = this.getSelectedReport(this.reports, this.profile.permissions);
    }
    this.selected = this.reports.find(obj => obj.title === this.selectedReport);
  },
  methods: {},
};
</script>

<style scoped>
.nav-item-report:hover {
  margin-left: 5px;
  transition: all 0.5s ease;
}
</style>
