<template>
  <b-card>
    <g-form @submit="print">
      <b-row>
        <!-- employee  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="employee"
              field="select"
              name="employeeId"
              :options="employees"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- department  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.departmentId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="department"
              field="select"
              name="departmentId"
              :options="departments"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- division  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.divisionId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="division"
              field="select"
              name="divisionId"
              :options="divisions"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- vacation  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.vacationId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="VacationType"
              field="select"
              name="vacationId"
              :options="vacations"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group :label="$t('allPeriods')">
            <b-form-checkbox
              v-model="helper.isAllPeriods"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- preview button -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            type="submit"
            variant="relief-primary"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
            />
            {{ $t("review") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups'

export default {
  mixins: [reportMixin],
  props: {
      type: {
        type: Object,
        default: null,
      },
    },
  data() {
    return {
      employees: [],
      departments: [],
      divisions: [],
      vacations: [],
      selectedItem: {
        fiscalYear: null,
        employeeId: null,
        departmentId: null,
        divisionId: null,
        vacationId: null
      },
      helper: {
        isAllPeriods: false
      }
    };
  },
  computed: {},
  watch: {
    'selectedItem.employeeId'(employeeId) {
      this.get({ url: 'Employees', id: employeeId }).then((data) => {
          this.selectedItem.departmentId = data.departmentId
          this.selectedItem.divisionId = data.divisionId
        })
    }
  },
  mounted() {
    this.getData();
    this.getmanagement();
  },
  methods: {
    getmanagement() {
        this.get({ url: 'Divisions' }).then((data) => {
          this.divisions = data;
        });
      },
    getData() {
      this.get({ url: 'Employees' }).then((data) => {
        this.employees = data;
      })
      .then(() => {
        this.get({ url: 'Departments' }).then((data) => {
          this.departments = data;
        });
      })
      .then(() => {
        this.get({ url: 'TypesVacations' }).then((data) => {
          this.vacations = data;
        });
      })
    },
    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },
    preparePrametersForPrint() {
      this.selectedItem.fiscalYear = this.helper.isAllPeriods
                                   ? null
                                   : this.currentYear.year.toString().includes('/') ? parseInt(this.currentYear.year.split('/')[0]) : this.currentYear.year;

      const selectedEmployee = this.filterDataBaseOnId(this.employees, this.selectedItem.employeeId);
      const allEmployees = this.filterDataBaseOnId(reportParameters, 'allEmployees');
      this.selectedItem.employeeName = this.selectedItem.employeeId
                                     ? this.isRight ? selectedEmployee.arabicName : selectedEmployee.englishName
                                     : this.isRight ? allEmployees.arabicName : allEmployees.englishName;

      const selectedDepartment = this.filterDataBaseOnId(this.departments, this.selectedItem.departmentId);
      const allDepartments = this.filterDataBaseOnId(reportParameters, 'allDepartments');
      this.selectedItem.departmentName = this.selectedItem.departmentId
                                       ? this.isRight ? selectedDepartment.arabicName : selectedDepartment.englishName
                                       : this.isRight ? allDepartments.arabicName : allDepartments.englishName;

      const selectedDivision = this.filterDataBaseOnId(this.divisions, this.selectedItem.divisionId);
      const allDivisions = this.filterDataBaseOnId(reportParameters, 'allDivisions');
      this.selectedItem.divisionName = this.selectedItem.divisionId
                                     ? this.isRight ? selectedDivision.arabicName : selectedDivision.englishName
                                     : this.isRight ? allDivisions.arabicName : allDivisions.englishName;

      const selectedVacation = this.filterDataBaseOnId(this.vacations, this.selectedItem.vacationId);
      const allVacations = this.filterDataBaseOnId(reportParameters, 'allVacations');
      this.selectedItem.vacationName = this.selectedItem.vacationId
                                     ? this.isRight ? selectedVacation.arabicName : selectedVacation.englishName
                                     : this.isRight ? allVacations.arabicName : allVacations.englishName
    },
    print() {
      this.preparePrametersForPrint();
      this.printReport(this.isRight ? 'EmployeesVacations-ar' : 'EmployeesVacations-en', this.selectedItem);
    },
  },
};
</script>
