<template>
  <b-card>
    <g-form @submit="print">

      <b-row>
        <!-- year  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.year"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="year"
              field="select"
              name="year"
              :options="years"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- preview button -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            type="submit"
            variant="relief-primary"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
            />
            {{ $t("review") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';
import { years, reportParameters } from '@/libs/acl/Lookups'

export default {
  mixins: [
    reportMixin,
  ],
  props: {
      type: {
        type: Object,
        default: null,
      },
    },
  data() {
    return {
      years: years,
      selectedItem: {
        year: null,
      },
    };
  },

  mounted() {
    this.selectedItem.year = new Date().getFullYear();
  },

  methods: {
    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    preparePrametersForPrint() {
      const selectedYear = this.filterDataBaseOnId(this.years, this.selectedItem.year);
      const allYears = this.filterDataBaseOnId(reportParameters, 'allYears');
      this.selectedItem.yearDisplayed = this.selectedItem.year
                                      ? selectedYear.arabicName
                                      : this.isRight ? allYears.arabicName : allYears.englishName;
    },

    print() {
      this.preparePrametersForPrint();
      this.printReport(this.isRight ? 'SalariesTotal-ar' : 'SalariesTotal-en', this.selectedItem);
    },
  },
};
</script>
